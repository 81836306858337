.menu-content{
    margin-left: 20%;
    font-family:"Lato";
}

.special-div{
    background-color: rgb(244, 242, 242);
    margin: 3rem 2rem 2rem 2rem;
    padding: 1rem;
    box-shadow: 0px 2px 2px rgba(0,0,0,.1);

    h1{
        font-size: 2.2rem;
        font-family:"Lato";
        letter-spacing: 2px;
        font-weight:300;
    }
}

.special-card{
    background-color: rgb(229, 226, 226);
    border-radius: .6rem;
    height: auto;
    width: 200px;
    margin: 1rem 1rem .5rem 1rem;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 2px 2px rgba(0,0,0,.1);
}

.menu-table{
    padding: 2rem;
    background-color: #B6BBC4;
    margin: 2rem;
    font-family: "Lato";
    text-align: center;
}
.table-head {
    background-color: #f5f5f5;
    font-size: 2rem;
    font-weight: bold;
}

.table-row {
    font-size: 1rem;
    text-align: center;
}

.table-row img {
    display: block;
    margin: 0 auto;
}

.menu-edit{
    color: rgb(66, 226, 66);
}

.menu-delete{
    color: rgb(216, 67, 67);
}

.menu-search{
    margin-bottom: 1rem;
    width: 50%;
    display: flex;
    align-items: center;
    position: relative;

    input{
    border-radius: .4rem;
    font-size: .8rem;
    background-color: white;
    width: 100%;
    font-size: 1rem;
    padding: 1rem 2rem;
    border: none;
    }
}
.menu-search:focus{
    outline: none;
}

.menu-img{
    border-radius: 50%;
    height: 4rem;
    width: 4rem;
    position: relative;
    object-fit: cover;
}

.modal-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: white;
    border: 2px solid #000;
    box-shadow: 24px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.modal-box h2 {
    margin-bottom: 20px;
}

.modal-box form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.add-item{
    background-color: rgb(119, 169, 243);
    color: white;
    padding: .9rem 2rem;
    border: none;
    border-radius: .4rem;
    margin-left: 2rem;
    margin-top: 1rem;
    cursor: pointer;
}

.upload-img{
    background-color: rgb(50, 213, 213);
    width: 60%;
}



.menu-file{
    margin-left: 2rem;
    width: 30%;
}


.loading-spinner2{
    position: absolute;
    background-color: transparent;
    margin-top: 5rem;
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading {
    color: #ff8800;
    animation: spin 1s linear infinite;
    font-size: 3rem;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.fa-trash-icon:hover{
    transform: scale(1.1);
    transition: .3s;
}