.offers-div{
    margin-left: 20%;
    margin-top: 2rem;
    font-family: "Lato";
    h1{
        text-align: center;
        font-size: 3rem;
        font-weight: 200;
        letter-spacing: 4px;
    }
}

.offers-div {
    padding: 2rem;
}

.offers-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 2rem;
}
.offer-item {
    padding: 1rem;
    border-radius: 5px;
    margin-bottom: 1rem;
    width: 200px;
    text-align: center;
    position: relative;
}

.offer-item-2 {
    border-radius: 5px;
    width: 300px;
    height: 300px;
    text-align: center;
    position: relative;
}

.offer-image {
    width: 100%;
    height: auto;
}

.modal-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: white;
    border: 2px solid #000;
    box-shadow: 24px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.disabled-button {
    cursor: not-allowed !important;
    background-color: lightorange !important;
}