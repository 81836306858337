.left {
    justify-content: center;
    font-family: "Lato";
    width: 20%;
    height: 100vh;
    position: fixed;
    z-index: 1000;
    top: 0;
    display: flex;
    flex-direction: row;
    background: #FEF5EB;
    border: .1px solid gray;
}

.left .line {
    width: 100%;
    border: 1px solid;
}

.leftContent {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
}

.leftContent h1 {
    border-radius: 10px;
    padding: 1rem 1.2rem;
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: 2px;
}

.navigation_Lists {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
}

.distnav--button {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    width: 100%;
    border: .1px solid rgb(162, 159, 159);
    cursor: pointer;
    padding: .9rem 2rem;
    background-color: white;
    transition: .5s all;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, .1);
    text-decoration: none;
    font-family: "Lato";
    letter-spacing: 2px;
    font-size: 1rem;
}

.nav-link {
    text-decoration: none;
    color: black;
    font-weight: 300;
    font-family: "Lato";
}

.activeSideNav {
    background-color: #facea0;
    color: black;
    border-radius: 5px;
    border: 1px solid aliceblue;
    transition: all 0.7s;
}

.activeSideNav .nav-link {
    text-decoration: none;
    font-size: 1rem;
    color: black;
    font-family: "Lato";
    font-weight: 300;
}